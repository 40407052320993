import { useEffect, useRef, useState } from 'react'
import { useRouteMatch, NavLink } from 'react-router-dom'
import {
  Icon,
  ListItemButton,
  ListItemIcon,
  SvgIconTypeMap,
  Tooltip,
  Typography,
  Theme,
} from '@mui/material'
import { Icon as MdiIcon } from '@mdi/react'
import { alpha } from '@mui/material/styles'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { highlightText } from './highlight-text'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigationLink: {
      fontSize: theme.typography.fontSize,
      color: theme.palette.text.primary,
      padding: theme.spacing(0),
      paddingLeft: theme.spacing(2),
      height: 30, //dense ? 40 : 'inherit',
      marginBottom: theme.spacing(0),
      borderTop: '1px solid #F2F2F2',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 1),
      },
    },
    navigationLinkText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      pr: 1,
      fontSize: 'inherit',
    },
  })
)

export function NavigationLink(props: NavLinkProps) {
  const { path, icon, children: text, iconPath, searchText } = props
  const isTabSelected = !!useRouteMatch({ path, exact: true })
  const classes = useStyles()

  const textRef = useRef<HTMLDivElement>(null)
  const [isOverflowed, setIsOverflowed] = useState(false)
  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowed(
          textRef.current.scrollWidth > textRef.current.clientWidth
        )
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow) // Check on resize as well
    return () => window.removeEventListener('resize', checkOverflow)
  }, [])

  return (
    <ListItemButton
      to={path}
      component={NavLink}
      sx={{
        backgroundColor: isTabSelected
          ? theme => alpha(theme.palette.primary.light, 1)
          : 'inherit',
      }}
      className={classes.navigationLink}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          marginRight: theme => theme.spacing(1),
          color: isTabSelected
            ? theme => alpha(theme.palette.text.primary, 1)
            : 'inherit',
        }}
      >
        {iconPath ? (
          <MdiIcon path={iconPath} size={0.1} />
        ) : (
          <Icon component={icon} sx={{ width: theme => theme.spacing(2) }} />
        )}
      </ListItemIcon>

      <Tooltip title={text} disableHoverListener={!isOverflowed}>
        <Typography noWrap ref={textRef} className={classes.navigationLinkText}>
          {highlightText(text, searchText)}
        </Typography>
      </Tooltip>
    </ListItemButton>
  )
}

type NavLinkProps = {
  path: string
  children: string
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  iconPath?: string
  dense?: boolean
  searchText?: string
}
