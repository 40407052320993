import { useState, useEffect } from 'react'
import cs from 'classnames'
import {
  Typography,
  Theme,
  Stack,
  Box,
  IconButton,
  Tooltip,
  Paper,
  alpha,
  Popover,
  Icon,
  TextField,
  Divider,
  Button,
  SvgIcon,
  useTheme,
  CircularProgress,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { Folder, REPORT_TYPES } from '../../../models'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useRoutes } from '../../../hooks'

import { ReactComponent as ReportGroupSVG } from '../../../assets/report-group.svg'
import { FolderItemTypeToIcon } from './util'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      borderRadius: theme.spacing(0.5),
      border: '2px solid',
      borderColor: theme.palette.secondary.main,
      '&:hover': {
        borderColor: alpha(theme.palette.secondary.main, 0.6),
      },
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0.5),
      '&:hover $reportTileButtons': {
        opacity: 1,
        visibility: 'visible !important',
      },
      maxWidth: theme.spacing(46.5),
      minWidth: theme.spacing(40),
      width: '100%',
      flexShrink: 1,
      minHeight: theme.spacing(8),
      alignItems: 'flex-start',
      height: '100%',
      // paddingRight: theme.spacing(1),
    },

    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      transition: 'background-color 0.3s',
    },

    reportTileButtons: {
      position: 'absolute',
      top: 1,
      right: 1,
      padding: '5px',
      zIndex: 2,
      backgroundColor: alpha(theme.palette.grey[100], 0.9),
      visibility: 'hidden',
      opacity: 1,
      //round the bottom left corner
      borderRadius: '0px 5px 0px 5px',
      transition: 'visibility 0.3s, opacity 0.3s',
    },

    actions: {
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      height: '100%',
      // paddingBottom: theme.spacing(1) + 'px!important',
      paddingRight: theme.spacing(1),
    },
    bottomContent: {
      display: 'flex',
      flexGrow: 1,
    },
    description: {
      flexGrow: 1,
    },
    openBtn: {
      alignSelf: 'flex-end',
    },
  }),
  { name: 'ReportTile' }
)

export function ReportTile(props: ReportTileProps) {
  const {
    title,
    description,
    type,
    isFavorite,
    isFavoritesLoading,
    handleFavoritesClicked,
    handleAddToFolder,
    folders,
    imageUrl,
  } = props

  const classes = useStyles()
  const routes = useRoutes()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  let history = useHistory()
  const [tempLoadingFavorites, setTempLoadingFavorites] = useState(false)

  useEffect(() => {
    setTempLoadingFavorites(true)
    setTimeout(() => {
      setTempLoadingFavorites(false)
    }, 1000)
  }, [handleFavoritesClicked])

  const handleFolderClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleFolderClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  //TODO: Add image url to the report tile if exists
  const bgColor =
    type === 'report-group'
      ? theme.palette.primary.main
      : theme.palette.secondary.main

  function ReportTileCircle() {
    return (
      <Box
        sx={{
          borderRadius: '50%',
          backgroundColor: alpha(bgColor, 0.2),
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minWidth: theme => theme.spacing(6),
          width: theme => theme.spacing(6.5),
          height: theme => theme.spacing(6.5),
        }}
      >
        {type === 'report-group' ? (
          imageUrl ? (
            <img
              src={imageUrl}
              alt=''
              width='100%'
              height='100%'
              style={{
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <SvgIcon
              sx={{
                width: '32px',
                height: '32px',
              }}
              component={ReportGroupSVG}
            />
          )
        ) : (
          <FolderItemTypeToIcon type={type} />
        )}
      </Box>
    )
  }

  function AddToFolderOptions() {
    const [isAddingFolder, setIsAddingFolder] = useState(false)

    const [newFolderName, setNewFolderName] = useState('')

    return (
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='flex-start'
        spacing={1}
        sx={{ p: 1, maxHeight: '280px', overflowY: 'auto', minWidth: '240px' }}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <Typography variant='body2' fontWeight='bold' sx={{ pl: 1 }}>
          Add to Folder
        </Typography>
        <Divider
          sx={{
            width: '100%',
          }}
        />
        <Stack
          onClick={e => {
            e.stopPropagation()
            setIsAddingFolder(true)
          }}
          direction={'row'}
          justifyContent={'flex-start'}
          sx={{
            cursor: 'pointer',
            borderRadius: '8px',
            width: '100%',
            p: 1,
            '&:hover': {
              backgroundColor: theme => alpha(theme.palette.primary.main, 0.2),
            },
            border: theme =>
              !isAddingFolder
                ? `1px dashed ${theme.palette.primary.main}`
                : 'none',
          }}
        >
          {isAddingFolder ? (
            <>
              <TextField
                autoFocus
                label='Folder Name'
                variant='standard'
                size='small'
                sx={{ width: '100%' }}
                value={newFolderName}
                onChange={e => setNewFolderName(e.target.value)}
                //if enter key is pressed, add folder
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setIsAddingFolder(false)
                    handleAddToFolder('', newFolderName)
                    handleFolderClose()
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <Typography
                      onClick={() => {
                        setIsAddingFolder(false)
                        handleAddToFolder('', newFolderName)
                        handleFolderClose()
                      }}
                      sx={{
                        fontSize: '14px',
                        mb: 1,
                        cursor: 'pointer',
                        color: theme => theme.palette.primary.main,
                        '&:hover': { textDecoration: 'underline' },
                      }}
                    >
                      Confirm
                    </Typography>
                  ),
                }}
              />
            </>
          ) : (
            <>
              <Typography
                fontStyle={'italic'}
                sx={{
                  cursor: 'pointer',
                }}
              >
                Create New
              </Typography>
              <Icon>
                <AddOutlinedIcon />
              </Icon>
            </>
          )}
        </Stack>

        {folders
          ?.filter(folder => !folder.disableEdit)
          ?.map((folder, index) => (
            <Box
              key={index}
              onClick={e => {
                e.stopPropagation()
                handleAddToFolder(folder.id)
                handleFolderClose()
              }}
              sx={{
                cursor: 'pointer',
                width: '100%',
                borderRadius: '8px',
                p: 1,
                '&:hover': {
                  backgroundColor: theme =>
                    alpha(theme.palette.primary.main, 0.2),
                },
              }}
            >
              <Typography>{folder.name}</Typography>
            </Box>
          ))}
      </Stack>
    )
  }

  function Overlay() {
    return <Button color='primary' className={cs(classes.overlay)} />
  }

  const handleClick = () => {
    if (type === 'report-group') {
      history.push(routes.reportGroups.group({ id: props.id }))
      return
    }
    history.push(routes.reports.report({ id: props.id }))
  }

  return (
    <Paper
      elevation={0}
      className={classes.root}
      variant='outlined'
      onClick={e => {
        e.stopPropagation()
        handleClick()
      }}
    >
      <Overlay />
      {type !== 'report-group' && (
        <Stack direction='row' className={classes.reportTileButtons}>
          <Tooltip
            title={isFavorite ? 'Unfavorite' : 'Favorite'}
            placement='top'
          >
            {isFavoritesLoading || tempLoadingFavorites ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '30px',
                }}
              >
                <CircularProgress
                  size={20}
                  sx={{
                    width: '20px',
                  }}
                />
              </Box>
            ) : (
              <IconButton
                aria-label='favorite'
                size='small'
                onClick={e => {
                  e.stopPropagation()
                  handleFavoritesClicked()
                }}
              >
                {isFavorite ? (
                  <StarIcon
                    sx={{
                      fontSize: '20px',
                    }}
                  />
                ) : (
                  <StarBorderIcon
                    sx={{
                      fontSize: '20px',
                    }}
                  />
                )}
              </IconButton>
            )}
          </Tooltip>

          <Tooltip title='Add To Folder' placement='top'>
            <IconButton
              aria-label='add-to-folder'
              size='small'
              onClick={e => {
                e.stopPropagation()
                handleFolderClick(e)
              }}
            >
              <FolderOutlinedIcon
                sx={{
                  fontSize: '20px',
                }}
              />
            </IconButton>
          </Tooltip>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={e => {
              //if click away from popover, stop propagation
              handleFolderClose()
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClick={e => {
              e.stopPropagation()
            }}
            slotProps={{
              paper: { sx: { borderRadius: '8px' } },
            }}
          >
            <AddToFolderOptions />
          </Popover>
        </Stack>
      )}

      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        gap={1}
      >
        <ReportTileCircle />
        <Stack direction='column' justifyContent='center'>
          <Typography
            gutterBottom
            variant='body2'
            color='textSecondary'
            fontWeight={'bold'}
            component='h2'
            display={'inline'}
          >
            {title}{' '}
          </Typography>

          <Box className={classes.bottomContent}>
            <Typography
              variant='body2'
              color='textPrimary'
              component='p'
              className={classes.description}
            >
              {description}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  )
}

type CardTypeBarProps = {
  type?: string
}

export function CardTypeBar(props: CardTypeBarProps) {
  const { type } = props

  if (!type || type.length === 0) return null

  const typeName = type.toUpperCase()

  return (
    <Stack
      direction='row'
      spacing={1}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: theme => getTypeColor(theme, type),
        opacity: 0.9,
        width: 'fit-content',
        px: 0.6,
        pt: 0.2,
        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
        borderRadius: '0px 0px 0px 8px',
        zIndex: 1,
        height: '20px',
      }}
    >
      <Typography
        color='textSecondary'
        fontSize={11}
        sx={{
          color: 'white',
        }}
      >
        {typeName}
      </Typography>
    </Stack>
  )
}

function getTypeColor(theme: Theme, type: string) {
  switch (type) {
    case REPORT_TYPES.DATASET:
      return theme.palette.primary.main
    case REPORT_TYPES.CUSTOM_PAGINATED:
      return theme.palette.primary.light
    default:
      return theme.palette.primary.main
  }
}

type ReportTileProps = {
  title: string
  description: string
  id: string
  type?: string
  isFavorite?: boolean
  handleFavoritesClicked: () => void
  folders: Folder[]
  imageUrl?: string
  isFavoritesLoading: boolean
  handleAddToFolder: (folderId: string, newFolderName?: string) => void
}
