import { useHistory } from 'react-router-dom'
import { useRoutes } from '../../../../hooks'
import { useState } from 'react'
import {
  Icon,
  Popover,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Folder, FolderItem } from '../../../../models'
import { useCreateFolder } from '../../../../hooks/mutations/useCreateFolder'
import { useUpdateFolder } from '../../../../hooks/mutations/useUpdateFolder'
import { AddToFolderOptions } from './add-to-folder-options'
import { CreateNewFolderOutlined } from '@mui/icons-material'
import { useAddItemsToFavorites } from '../../../../hooks/mutations/useAddItemToFavoritesFolder'
import { useRemoveItemFromFavorites } from '../../../../hooks/mutations/useRemoveItemFromFavoritesFolder'
import { getItemIconByType } from './folder-utilities'
import MoreVertIcons from '@mui/icons-material/MoreVert'

type FolderItemProps = {
  item: FolderItem
  provided: any
  onRemove: () => void
  disableEdit?: boolean
  foldersAvailable: Folder[]
}

export function SingleFolderItem(props: FolderItemProps) {
  const { item, provided, onRemove, disableEdit, foldersAvailable } = props

  const routes = useRoutes()
  const history = useHistory()

  const { mutateAsync: addToFavorites } = useAddItemsToFavorites()
  const { mutateAsync: removeFromFavorites } = useRemoveItemFromFavorites()

  const [isHovered, setIsHovered] = useState(false)

  const [isAddingToFolder, setIsAddingToFolder] = useState(false)
  const [isFavoritesLoading, setIsFavoritesLoading] = useState(false)

  const { mutateAsync: updateFolder } = useUpdateFolder()
  const { mutateAsync: createFolder } = useCreateFolder()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleMoreClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleFavoriteClicked = async () => {
    setIsFavoritesLoading(true)
    if (item.isFavorite) {
      await removeFromFavorites({ itemId: item.id })
    } else {
      await addToFavorites({ item: item })
    }
    setTimeout(() => {
      setIsFavoritesLoading(false)
      handlePopoverClose()
    }, 1000)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setIsAddingToFolder(false)
  }

  const onItemClicked = () => {
    if (item.accessDenied) return
    //set URL based on item type
    if (item.type === 'bookmark' && item.reportId) {
      history.push(
        routes.reports.report({
          id: item.reportId,
          bookmark: item.id,
        })
      )
      return
    }

    history.push(
      routes.reports.report({
        id: item.id,
      })
    )
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent={'space-between'}
      gap={1}
      sx={{
        borderTop: '1px solid #F2F2F2',
        borderRadius: '0px',
        py: 0.5,
        px: 1,
        transition: '1.5s',
        '&:hover': {
          color: theme => theme.palette.primary.main,
          textDecoration: 'underline',
          textUnderlineOffset: theme => theme.spacing(0.5),
          //backgroundColor: item.accessDenied ? 'default' : 'red', //'rgba(0, 0, 0, 0.1)',
        },
        width: '100%',
        cursor: item.accessDenied ? 'default' : 'pointer',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onItemClicked}
      ref={provided.innerRef}
      {...(!disableEdit ? provided.draggableProps : {})}
    >
      <Stack direction='row' alignItems={'center'} gap={1}>
        <Icon
          {...provided.dragHandleProps}
          sx={{
            cursor: disableEdit ? 'inherit' : 'grab',
          }}
        >
          {getItemIconByType(item.type, { fontSize: '14px', mb: 0.5 })}
        </Icon>
        <Typography
          display='inline-block'
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            color: theme => theme.palette.primary.main,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '236px',
          }}
        >
          {item.name}
        </Typography>
        {item.accessDenied && (
          <Typography
            display='inline-block'
            fontSize={'14px'}
            sx={{
              color: theme => theme.palette.error.main,
              fontStyle: 'italic',
            }}
          >
            [Access Denied]
          </Typography>
        )}
      </Stack>
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        {isHovered && !disableEdit && (
          <IconButton
            onClick={e => {
              e.stopPropagation()
              handleMoreClicked(e)
            }}
            sx={{
              cursor: 'pointer',
              height: '20px',
              width: '20px',
              '&:hover': {
                color: theme => theme.palette.primary.light,
              },
              zIndex: 10,
            }}
          >
            <MoreVertIcons />
          </IconButton>
        )}
        <Stack
          sx={{
            width: '24px',
            color: theme => theme.palette.secondary.main,
            zIndex: 10,
          }}
        >
          {isFavoritesLoading ? (
            <CircularProgress size={20} />
          ) : item?.isFavorite ? (
            <StarIcon
              onClick={e => {
                e.stopPropagation()
                handleFavoriteClicked()
              }}
              sx={{
                cursor: 'pointer',
                color: theme => theme.palette.primary.main,
                '&:hover': {
                  color: theme => theme.palette.primary.light,
                },
              }}
            />
          ) : (
            <StarBorderIcon
              onClick={e => {
                e.stopPropagation()
                handleFavoriteClicked()
              }}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: theme => theme.palette.primary.light,
                },
              }}
            />
          )}
        </Stack>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { sx: { borderRadius: '8px' } },
        }}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        {isAddingToFolder ? (
          <AddToFolderOptions
            folders={foldersAvailable}
            handleAddToFolder={async (
              folderId: string,
              newFolderName?: string
            ) => {
              if (!item.id) return
              if (newFolderName && folderId === '') {
                await createFolder({
                  folderName: newFolderName,
                  items: [
                    {
                      id: item.id,
                      type: item.type || 'report',
                      name: item.name,
                      reportId: item?.reportId,
                    },
                  ],
                })
                return
              }

              const folder = foldersAvailable?.find(
                folder => folder.id === folderId
              )

              if (!folder) return
              await updateFolder({
                folderId: folder.id,
                items: [
                  ...folder.items,
                  {
                    id: item.id,
                    type: item.type || 'report',
                    name: item.name,
                    reportId: item?.reportId,
                  },
                ],
              })
            }}
            handleFolderClose={() => {
              setIsAddingToFolder(false)
              handlePopoverClose()
            }}
          />
        ) : (
          <Stack
            direction='column'
            sx={{
              backgroundColor: '#f0f4f8',
              p: 1,
            }}
            gap={1}
          >
            {!disableEdit && (
              <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    color: theme => theme.palette.primary.light,
                  },
                }}
                onClick={e => {
                  e.stopPropagation()
                  onRemove()
                  handlePopoverClose()
                }}
                spacing={1}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{
                    mt: 1,
                    fontSize: '20px',
                  }}
                />
                <Typography>Remove</Typography>
              </Stack>
            )}
            <Stack
              direction={'row'}
              alignItems={'center'}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: theme => theme.palette.primary.light,
                },
              }}
              onClick={e => {
                e.stopPropagation()
                setIsAddingToFolder(true)
              }}
              spacing={1}
            >
              <CreateNewFolderOutlined
                sx={{
                  mt: '2px',
                  fontSize: '20px',
                }}
              />
              <Typography>Add To Folder</Typography>
            </Stack>
          </Stack>
        )}
      </Popover>
    </Stack>
  )
}
