import { FC, useEffect, useState } from "react";
import { useCreateWorkspace } from "../../../../hooks/mutations/useCreateWorkspace";
import { Profile, StatusMessageInfo, Workspace } from "../../../../models";
import { FormField, StatusMessage } from "../../shared";
import {
  useGetCapacities,
  useGetProfiles,
  useGetWorkspaces,
  useUpdateWorkspace,
} from "../../../../hooks";
import { Capacity } from "../reports/add-report-form/types";
import { SkeletonFormField } from "../reports";
import CustomDialog from "../../shared/dialog/dialog";

export type WorkspaceFormProps = {
  onClose: () => void;
  editMode?: boolean;
  workspace?: Workspace;
  open: boolean;
};

const illegalCharsRegex = /[/\\?%*:|"<>.']/g;

export const WorkspaceForm: FC<WorkspaceFormProps> = (
  props: WorkspaceFormProps
) => {
  const { onClose, workspace, editMode, open } = props;

  const { mutateAsync: createWorkspace, isLoading: isSaving } =
    useCreateWorkspace();

  const { mutateAsync: updateWorkspace, isLoading: isUpdating } =
    useUpdateWorkspace();

  const { data: profiles, isLoading: loadingProfiles } = useGetProfiles();

  const [profile, setProfile] = useState<Profile | null>(null);
  const [capacity, setCapacity] = useState<Capacity | null>(null);

  const { data: currentWorkspaces } = useGetWorkspaces();

  const {
    data: capacities,
    isLoading: loadingCapacities,
    refetch: refetchCapacities,
    isRefetching: isRefetchingCapacities,
  } = useGetCapacities(profile?.id);

  useEffect(() => {
    if (!profile) return;
    refetchCapacities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const [workspaceName, setWorkspaceName] = useState<string>(
    workspace?.name || ""
  );
  const [formError, setFormError] = useState<string>("");
  const [status, setStatus] = useState<StatusMessageInfo | null>(null);
  const workspaceNames = currentWorkspaces?.map((g) => g.name.toLowerCase());

  useEffect(() => {
    if (profiles?.length === 1) {
      setProfile(profiles[0]);
    }
  }, [profiles]);

  useEffect(() => {
    if (capacities?.length === 1) {
      setCapacity(capacities[0]);
    }
  }, [capacities]);

  const onWorkspaceNameChange = (name: string) => {
    setWorkspaceName(name);

    //if workspace name contains any illegal characters, set error
    const illegalChars = name.match(illegalCharsRegex);
    if (illegalChars) {
      setFormError(
        "Name cannot contain any of the following characters: / \\ ? % * : | \" ' < > ."
      );
      return;
    }

    //if workspace name already exists, set error
    if (
      workspaceNames?.includes(name.trim().toLowerCase()) &&
      name !== workspace?.name.toLowerCase()
    ) {
      setStatus({
        type: "warning",
        title: "Name Conflict",
        message: "Workspace name already exists",
      });
      return;
    }

    setFormError("");
  };

  const handleSave = async (): Promise<void> => {
    if (formError) return;
    const trimmedWorkspaceName = workspaceName.trim();

    if (!trimmedWorkspaceName) {
      setFormError("Workspace name is required");
      return;
    }

    try {
      if (editMode) {
        await updateWorkspace({
          workspaceName: trimmedWorkspaceName,
          workspaceId: workspace.id,
        });
        onClose();
        return;
      }
      await createWorkspace({
        name: trimmedWorkspaceName,
        profileId: profile?.id,
        capacityId: capacity?.id,
        description: "",
      });

      onClose();
    } catch (error) {
      setStatus({
        type: "error",
        message: `An error occurred while saving the workspace ${error.response.data?.errorMessage}`,
        title: "Error",
      });
    }
  };

  const ConnectionProfileFormField = () => {
    if (loadingProfiles) {
      return <SkeletonFormField label={"Connection Profile"} />;
    }

    return (
      <FormField
        label={"Connection Profile"}
        value={profile?.name || ""}
        onTextChange={(value) => {
          let selectedProfile = profiles?.find(
            (profile) => profile?.name === value
          );
          if (!selectedProfile) return;
          setProfile(selectedProfile);
        }}
        disabled={!profiles || profiles.length === 0 || editMode}
        selectOptions={profiles?.map((profile) => profile?.name) || []}
        id="profiles-form-field"
      />
    );
  };
  const CapacitiesFormField = () => {
    if (loadingCapacities || isRefetchingCapacities) {
      return <SkeletonFormField label={"Capacity"} />;
    }

    //if there is only one capacity, set it as the default
    if (capacities?.length === 1 && !capacity) {
      setCapacity(capacities[0]);
    }

    return (
      <FormField
        id="capacities-form-field"
        label={"Capacity"}
        value={capacity?.displayName || ""}
        onTextChange={(value) => {
          let selectedCapacity: Capacity = capacities?.find(
            (capacity) => capacity?.displayName === value
          );

          if (!selectedCapacity) return;
          setCapacity(selectedCapacity);
        }}
        disabled={!capacities || capacities.length === 0 || editMode}
        selectOptions={
          capacities?.map((capacity) => capacity?.displayName) || []
        }
      />
    );
  };
  

  return (
    <CustomDialog
      error={formError}
      open={open}
      onClose={onClose}
      loading={isSaving || isUpdating}
      primaryButtonProps={{
        onClick: handleSave,
        children: editMode ? "Edit Workspace" : "Create Workspace",
      }}
      secondaryButtonProps={{
        onClick: onClose,
        children: "Cancel",
      }}
      paperSx={{ height: "400px" }}
    >
      <ConnectionProfileFormField />
      <CapacitiesFormField />
      <FormField
        label="Workspace name"
        type="text"
        id="workspace-name"
        onTextChange={onWorkspaceNameChange}
        value={workspaceName}
      ></FormField>
      {/* <FormField
        label='Description'
        onTextChange={onWorkspaceNameChange}
        value={workspaceName}

      ></FormField> */}
      {status && <StatusMessage status={status} />}
    </CustomDialog>
  );
};
