import { FC, PropsWithChildren } from 'react'
import {
  IconButton,
  Typography,
  Theme,
  Stack,
  SvgIconProps,
  Divider,
  Snackbar,
} from '@mui/material'
import { SxProps } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import { StyledToastBox } from './Toast.styles'

export type ToastProps = {
  open: boolean
  message: string | React.ReactElement
  onClose?: () => void
  variant?: 'primary' | 'success' | 'warning' | 'error'
  icon?: React.ReactElement<SvgIconProps>
  autoTimeout?: number
  sx?: SxProps<Theme>
}

export const ToastNotification: FC<PropsWithChildren<ToastProps>> = (
  props: PropsWithChildren<ToastProps>
) => {
  const setBackgroundColor = (theme: Theme) => {
    let backgroundColor = undefined
    switch (props.variant) {
      case 'primary':
        backgroundColor = theme.palette?.primary.main
        break
      case 'success':
        backgroundColor = theme.palette?.success?.main
        break
      case 'warning':
        backgroundColor = theme.palette?.warning?.main
        break
      case 'error':
        backgroundColor = theme.palette?.error?.main
        break
      default:
        break
    }
    return backgroundColor
  }

  return (
    <Snackbar
      open={props.open}
      onClose={() => props.onClose?.()}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        top: '0 !important',
        width: '100%',
        ...(props.sx as {}),
      }}
      ClickAwayListenerProps={{ onClickAway: () => undefined }}
      autoHideDuration={props?.autoTimeout || 6000}
    >
      <StyledToastBox
        sx={{
          backgroundColor: (theme: Theme) => setBackgroundColor(theme),
          color: (theme: Theme) => theme.palette.common.white,
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{ width: '100%' }}
          gap={2}
        >
          {props.icon}
          <Typography
            variant='body1'
            sx={{
              color: (theme: Theme) => theme.palette.common.white,
            }}
          >
            {props.message}
          </Typography>
          {props.onClose && (
            <>
              <Divider
                orientation='vertical'
                sx={{
                  color: (theme: Theme) => theme.palette.common.white,
                  background: (theme: Theme) => theme.palette.common.white,
                }}
              />
              <IconButton
                onClick={() => props.onClose?.()}
                sx={{
                  color: (theme: Theme) => theme.palette.common.white,
                  p: 0,
                }}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </>
          )}
        </Stack>
      </StyledToastBox>
    </Snackbar>
  )
}
