import CustomDialog from '../../../shared/dialog/dialog'
import { CircularProgress, Typography } from '@mui/material'
import { Folder } from '../../../../../models'

function DeleteFolderDialog(props: DeleteFolderDialogProps) {
  const { open, onClose, folder, handleDelete, isDeleting } = props

  if (!folder) return null

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={`Delete Folder?`}
      primaryButtonProps={{
        disabled: isDeleting,
        children: 'Delete',
        onClick: async () => {
          await handleDelete()
          onClose()
        },
        endIcon: isDeleting ? <CircularProgress size={20} /> : null,
      }}
      secondaryButtonProps={{
        disabled: isDeleting,
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      {isDeleting ? (
        <Typography>Deleting Folder...</Typography>
      ) : (
        <Typography>
          Are you sure you want to delete the Folder "{folder.name}"? This
          action cannot be undone.
        </Typography>
      )}
    </CustomDialog>
  )
}

type DeleteFolderDialogProps = {
  open: boolean
  onClose: () => void
  handleDelete: () => Promise<void>
  folder: Folder
  isDeleting: boolean
}

export default DeleteFolderDialog
