import {
  TextField,
  Theme,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

/**
 * Can be used as a text field, or a select field by passing
 * the `selectOptions` prop
 */
export function FormField(props: FormFieldProps) {
  const {
    selectOptions,
    label,
    value,
    helperText,
    onTextChange,
    errorMessage,
    allowEmpty,
    onBlur,
    id,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Stack
      direction="column"
      sx={{
        width: "100%",
      }}
      className={classes.textField}
    >
      <Typography
        variant="body1"
        sx={{
          textAlign: "left",
        }}
        fontWeight={600}
      >
        {label}
      </Typography>
      <FormControl
        variant="outlined"
        sx={{ width: "100%", height: "30px !important" }}
        fullWidth
        error={!!errorMessage}
      >
        {selectOptions ? (
          <>
            <Select
              {...rest}
              color={errorMessage ? "error" : "secondary"}
              variant="outlined"
              fullWidth
              value={value}
              onBlur={onBlur || (() => {})}
              onChange={(e) => {
                if (allowEmpty && e.target.value === "(None)") {
                  onTextChange("");
                  return;
                }

                onTextChange(e.target.value);
              }}
              id={id}
              sx={{
                height: "30px !important",
                "& .MuiOutlinedInput-input": {
                  padding: "5px 10px",
                },
              }}
            >
              {renderMenuItems(selectOptions, allowEmpty)}
            </Select>
          </>
        ) : (
          <TextField
            {...rest}
            color={errorMessage ? "error" : "secondary"}
            variant="outlined"
            fullWidth
            value={value}
            onBlur={onBlur || (() => {})}
            onChange={(e) => onTextChange(e.target.value)}
            id={id}
            sx={{
              height: "30px !important",
              "& .MuiOutlinedInput-input": {
                padding: "5px 10px",
              },
            }}
          />
        )}
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    </Stack>
  );
}

export type FormFieldProps = {
  id?: string;
  selectOptions?: string[] | { [value: string]: string };
  label: string;
  value: string;
  helperText?: string;
  disabled?: boolean;
  defaultValue?: string;
  type?: string;
  errorMessage?: string;
  onTextChange?: (val: string) => void;
  onBlur?: () => void;
  allowEmpty?: boolean;
};

/**
 * Accepts either an array of strings to use as select options
 * or an object whose keys are the select values, and the key-values
 * are the strings to display in the UI for each option
 */
function renderMenuItems(
  options: FormFieldProps["selectOptions"],
  allowEmpty?: boolean
) {
  if (!options) return null;

  let optionsToReturn;

  if (Array.isArray(options)) {
    optionsToReturn = options.map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ));
  } else {
    optionsToReturn = Object.entries(options).map(([value, displayText]) => (
      <MenuItem key={value} value={value}>
        {displayText}
      </MenuItem>
    ));
  }

  if (allowEmpty) {
    optionsToReturn.unshift(
      <MenuItem key="(None)" value="(None)">
        (None)
      </MenuItem>
    );
  }

  return optionsToReturn;
}
