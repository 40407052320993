import { ThemeOptions } from '@mui/material'
import '@fontsource/open-sans'

// TODO: move this to another file later
export const defaultTheme: ThemeOptions = {
  shape: {
    borderRadius: 5,
  },
  spacing: 10,
  palette: {
    primary: {
      light: '#CAE6FF',
      main: '#006EB3',
      dark: '#0082E4',
      contrastText: '#fff',
    },
    success: {
      main: '#5eb95e',
    },
    secondary: {
      light: '#fff',
      main: '#9DD5FF',
      dark: '#006EB3',
      contrastText: '#006EB3',
    },
    error: {
      light: '#fff',
      main: '#FFE3EB',
      dark: '#DA4760',
      contrastText: '#DA4760',
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#3F3F3F',
      secondary: '#0075CE',
      disabled: '#8F8F8F',
    },
    info: {
      light: '#F2F2F2',
      main: '#dce2e8',
      dark: '#C2C2C2',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '40px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 0,
        },
      },
    },
  },

  typography: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeightBold: 600,
    fontWeightMedium: 400,
    fontWeightRegular: 400,
    fontWeightLight: 400,
    fontSize: 13,
    h1: {
      fontSize: '0.8125rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '0.8125rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },
    h6: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.8125rem',
      textTransform: 'capitalize',
    },
    caption: {
      fontSize: '0.8125rem',
    },
    overline: {
      fontSize: '0.8125rem',
      textTransform: 'uppercase',
    },
  },
}
