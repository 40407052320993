import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { RlsFields } from "../rls-fields";
import { FormField, StatusMessage } from "../../../shared";
import { AccessLoadingBar } from "./access-loading-bar";
import { SkeletonFormField } from "./skeleton-form-field";
import {
  ApiReport,
  Profile,
  REPORT_TYPES,
  ReportConnectionStatus,
  StatusMessageInfo,
  WorkspaceDatasetInfo,
} from "../../../../../models";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import {
  useTestReportConnection,
  useGetWorkspaceReports,
  useGetProfileWorkspaces,
  useGetProfiles,
} from "../../../../../hooks";
import { connectionStatusInfo } from "../test-connection-responses";
import { useAddProfileToWorkspace } from "../../../../../hooks/mutations/useAddProfileToWorkspace";
import ToggleTypeButtons from "./item-type-toggle";
import {
  // ItemPermissionSettings,
  PermissionSettings,
} from "./item-permission-settings";
import { workspacesApi } from "../../../../../api-interface";
import { FolderItemTypeToIcon } from "../../../../reports/report-dashboard/util";

export type SelectAndAuthenticateReportProps = {
  report: ApiReport;
  setReport: (report: ApiReport) => void;
  profile: Profile;
  setProfile: (profile: Profile) => void;
  status: StatusMessageInfo | null;
  setStatus: (status: StatusMessageInfo | null) => void;
  isGrantingAccess: boolean;
  setIsGrantingAccess: (value: boolean) => void;
  typeOption: "report" | "paginated-report" | "dataset" | "all";

  setTypeOption: (
    type: "report" | "paginated-report" | "dataset" | "all"
  ) => void;
  type: "report" | "paginated-report" | "dataset";
  isEdit?: boolean;
  setType: (type: "report" | "paginated-report" | "dataset") => void;
  perspectives: string[];
  perspectivesLoading: boolean;
  dataset: WorkspaceDatasetInfo | null;
  setDataset: (dataset: WorkspaceDatasetInfo) => void;
  permissions: PermissionSettings;
  setPermissions: (permissions: PermissionSettings) => void;
  allTypes: boolean;
  setAllTypes: (allTypes: boolean) => void;
  autoTestConnection: boolean;
};

const useStyles = makeStyles(
  () =>
    createStyles({
      statusMessage: {
        width: "100%",
      },
      rlsSection: {
        width: "100%",
      },
    }),
  { name: "add-report-form" }
);

export function SelectAndAuthenticateReport(
  props: SelectAndAuthenticateReportProps
) {
  const {
    report,
    setReport,
    profile,
    isEdit,
    setProfile,
    status,
    setStatus,
    isGrantingAccess,
    setIsGrantingAccess,
    typeOption,
    setTypeOption,
    perspectives,
    perspectivesLoading,
    dataset,
    setDataset,
    setType,
    autoTestConnection,
    type,
  } = props;

  const classes = useStyles();

  const { mutateAsync: testReportConnection, isLoading: isTestingConnection } =
    useTestReportConnection();

  const { data: profiles, isLoading: loadingProfiles } = useGetProfiles();

  const [workspaceDatasets, setWorkspaceDatasets] = useState<
    WorkspaceDatasetInfo[]
  >([]);
  const [loadingWorkspaceDatasets, setLoadingWorkspaceDatasets] =
    useState(false);

  const isInitialRender = useRef(true);

  const {
    data: workspaces,
    isLoading: loadingWorkspaces,
    refetch: refetchProfileWorkspaces,
    isRefetching: isRefetchingProfileWorkspaces,
  } = useGetProfileWorkspaces(profile?.id);

  const {
    mutateAsync: addProfileToWorkspace,
    isLoading: isAddingProfile,
    isError,
  } = useAddProfileToWorkspace();

  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState("");

  const {
    data: workspaceReports,
    isLoading: loadingWorkspaceReports,
    refetch: refetchWorkspaceReports,
    isRefetching: isRefetchingWorkspaceReports,
  } = useGetWorkspaceReports(profile?.id, report?.pbiWorkspaceId);

  const [reportName, setReportName] = useState("");

  const [isRlsRequired, setIsRlsRequired] = useState(false);

  const [allOptions, setAllOptions] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  useEffect(() => {
    if (!report || !isEdit || !workspaceDatasets) return;

    if (!report.datasetId || report.type !== REPORT_TYPES.DATASET) return;

    const selectedDataset = workspaceDatasets.find(
      (dataset) => dataset.id === report.datasetId
    );

    if (!selectedDataset) return;

    setDataset(selectedDataset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.datasetId, workspaceDatasets]);

  useEffect(() => {
    if (
      loadingWorkspaceReports ||
      isRefetchingWorkspaceReports ||
      isRefetchingProfileWorkspaces ||
      loadingWorkspaceDatasets
    ) {
      return;
    }

    let tempWorkspaceReports = workspaceReports || [];
    let tempWorkspaceDatasets = workspaceDatasets || [];

    const options = [
      ...tempWorkspaceReports?.map((r) => {
        return {
          ...r,
          name: r.name,
          type:
            r.reportType === "PaginatedReport" ? "paginated-report" : "report",
        };
      }),
      ...tempWorkspaceDatasets?.map((d) => {
        return {
          ...d,
          name: d.name,
          type: "dataset",
        };
      }),
    ];
    setAllOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceReports, workspaceDatasets, workspaces]);

  const ItemsSearch = () => {
    const [clearValueToggle, setClearValueToggle] = useState(false);

    if (
      loadingWorkspaceReports ||
      isRefetchingWorkspaceReports ||
      isRefetchingProfileWorkspaces ||
      loadingWorkspaceDatasets
    ) {
      return <SkeletonFormField label={"Select an Item"} />;
    }
    return (
      <Stack
        direction="column"
        gap={2}
        sx={{
          width: "100%",
          mb: 3,
        }}
      >
        <Autocomplete
          id="select-items"
          key={clearValueToggle + "autocomplete"}
          options={allOptions}
          disabled={!report.pbiWorkspaceId}
          getOptionLabel={(option) => option.name}
          onChange={(_event, newValue: any) => {
            if (!newValue) return;
            setSelectedOption(newValue);
            setType(newValue.type);
            setReport({
              ...report,
              name: newValue.name,
              url: newValue?.webUrl,
              pbiReportId: newValue.type !== "dataset" ? newValue.id : "",
              pbiReportName: newValue.name,
              datasetId: newValue.type === "dataset" ? newValue.id : "",
            });
            newValue.type === "dataset" && setDataset(newValue);
            setClearValueToggle((prev) => !prev);
          }}
          renderOption={(props, option: any) => (
            <li {...props}>
              <Stack direction="row" gap={2} alignItems="center">
                <FolderItemTypeToIcon type={option.type} />
                <Typography>{option.name}</Typography>
              </Stack>
            </li>
          )}
          value={selectedOption}
          fullWidth
          //drop down styles background color:
          sx={{
            mt: 1,
            height: "30px",
            "& .MuiOutlinedInput-root": {
              height: "30px",
            },
            "& .MuiOutlinedInput-input": {
              height: "20px",
              padding: "5px 10px",
            },
          }}
          disableClearable
          renderInput={(params) => (
            <Stack
              direction="column"
              sx={{
                width: "100%",
              }}
              // className={classes.textField}
            >
              <Typography
                variant="body1"
                sx={{
                  textAlign: "left",
                }}
                fontWeight={600}
              >
                Select an Item
              </Typography>
              <TextField
                {...params}
                sx={{
                  height: "30px !important",
                  "& .MuiOutlinedInput-input": {
                    padding: "5px 10px",
                  },
                }}
              />
            </Stack>
          )}
        />
      </Stack>
    );
  };

  async function testConnection() {
    if (!report.pbiWorkspaceId || !profile) return;

    if (type === "dataset" && !dataset) return;
    if (type === "report" && !report.pbiReportId) return;

    let status = await testReportConnection({
      pbiWorkspaceId: report.pbiWorkspaceId,
      itemId: type === "dataset" ? dataset?.id : report?.pbiReportId,
      profileId: profile.id,
      type: type,
    });

    setStatus(connectionStatusInfo[status]);

    const rlsRequired = status === ReportConnectionStatus.RowLevelSecurity;
    setIsRlsRequired(rlsRequired);

    if (rlsRequired && !report.rls) {
      setReport({
        ...report,
        rls: { roles: [""], username: "email" },
      });
    }
  }

  useEffect(() => {
    if (!report?.pbiWorkspaceId) return;

    getWorkspaceDatasets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.pbiWorkspaceId, profile]);

  const getWorkspaceDatasets = async () => {
    setLoadingWorkspaceDatasets(true);
    try {
      const workspaceDatasetsResponse =
        await workspacesApi.getWorkspaceDatasets(
          profile.id,
          report.pbiWorkspaceId
        );

      setWorkspaceDatasets(workspaceDatasetsResponse);
      setStatus(null);
    } catch (e) {
      setStatus({
        title: "Unauthorized",
        message:
          "The app registration and/or profile does not have access to the workspace",
        type: "error",
      });
    } finally {
      setLoadingWorkspaceDatasets(false);
    }
  };

  useEffect(() => {
    if (profile) {
      refetchProfileWorkspaces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    const workspaceName = workspaces?.find(
      (ws) => ws.id === report.pbiWorkspaceId
    )?.name;

    setSelectedWorkspaceName(workspaceName);
    setReport({
      ...report,
    });
    setDataset(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, report.pbiWorkspaceId]);

  useEffect(() => {
    refetchWorkspaceReports();
    setStatus(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspaceName, profile]);

  useEffect(() => {
    if (isEdit || !autoTestConnection) return;

    if (isInitialRender.current) {
      // Skip the first render
      isInitialRender.current = false;
      return;
    }

    if (profile && report?.pbiWorkspaceId) {
      testConnection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profile,
    report?.pbiWorkspaceId,
    report?.pbiReportId,
    dataset?.id,
    report?.datasetId,
    dataset,
  ]);

  //If there is only one, select it
  useEffect(() => {
    if (profiles?.length === 1) {
      setProfile(profiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profiles]);

  useEffect(() => {
    if (workspaceDatasets?.length === 1) {
      setDataset(workspaceDatasets[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDatasets]);

  useEffect(() => {
    if (perspectives?.length === 1) {
      setReport({ ...report, perspective: perspectives[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perspectives]);

  useEffect(() => {
    if (workspaces?.length === 1) {
      setSelectedWorkspaceName(workspaces[0].name);
      setReport({
        ...report,
        pbiWorkspaceId: workspaces[0].id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces]);

  const reportOptions = workspaceReports?.filter(
    (r) =>
      r.reportType ===
      (typeOption === "paginated-report" ? "PaginatedReport" : "PowerBIReport")
  );

  useEffect(() => {
    setReportName(
      workspaceReports?.find((r) => r.id === report.pbiReportId)?.name || ""
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.pbiReportId, workspaceReports]);

  useEffect(() => {
    setReport({
      ...report,
      datasetId: dataset?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset?.id]);

  useEffect(
    () => {
      if (reportOptions?.length === 1) {
        const selectedReport = reportOptions[0];
        if (!selectedReport) return;
        const updatedReport = {
          ...report,
          name: selectedReport.name,
          displayName: selectedReport.name,
          url: selectedReport.webUrl,
          pbiReportId: selectedReport.id,
          pbiReportName: selectedReport.name,
        };
        setReport(updatedReport);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportOptions]
  );

  const DatasetsFormField = () => {
    if (loadingWorkspaceDatasets) {
      return <SkeletonFormField label="Select Dataset" />;
    }

    const availableDatasets =
      !report?.pbiWorkspaceId || !profile || status?.title === "Unauthorized"
        ? []
        : workspaceDatasets;

    if (availableDatasets?.length === 1 && !dataset?.id) {
      setDataset(availableDatasets[0]);
    }

    return (
      <FormField
        label={
          !availableDatasets || availableDatasets.length === 0
            ? "No datasets found"
            : "Select Dataset"
        }
        value={dataset?.name || ""}
        onTextChange={(value) => {
          setStatus(null);
          let selectedDataset = availableDatasets?.find(
            (dataset) => dataset?.name === value
          );
          if (!selectedDataset) return;
          setDataset(selectedDataset);
        }}
        disabled={
          !profiles ||
          profiles.length === 0 ||
          !report.pbiWorkspaceId ||
          status?.title === "Unauthorized"
        }
        selectOptions={availableDatasets?.map((dataset) => dataset?.name) || []}
      />
    );
  };

  const PerspectivesFormField = () => {
    if (perspectivesLoading) {
      return <SkeletonFormField label={"Perspectives (Optional)"} />;
    }

    if (perspectives?.length === 0) {
      return null;
    }

    return (
      <FormField
        label={"Perspectives (Optional)"}
        value={
          perspectives?.length > 0
            ? report?.perspective
            : "No perspectives found"
        }
        onTextChange={(value) => {
          setStatus(null);
          setReport({ ...report, perspective: value });
        }}
        disabled={
          !profiles ||
          profiles.length === 0 ||
          !report?.pbiWorkspaceId ||
          status?.title === "Unauthorized"
        }
        allowEmpty
        selectOptions={perspectives}
      />
    );
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      width="540px"
    >
      <>
        {loadingProfiles ? (
          <SkeletonFormField label="Select Connection Profile" />
        ) : (
          <FormField
            label={
              !profiles || profiles.length === 0
                ? "No profiles found"
                : "Select Connection Profile"
            }
            value={profile?.name || ""}
            onTextChange={(value) => {
              setStatus(null);
              setIsRlsRequired(false);
              let selectedProfile: Profile = profiles?.find(
                (profile) => profile?.name === value
              );
              if (!selectedProfile) return;
              setProfile(selectedProfile);
            }}
            disabled={!profiles || profiles.length === 0}
            selectOptions={profiles?.map((profile) => profile?.name) || []}
          />
        )}
      </>
      <>
        {loadingWorkspaces || isRefetchingProfileWorkspaces ? (
          <SkeletonFormField label="Workspace" />
        ) : (
          <FormField
            label="Workspace"
            disabled={!profile && !selectedWorkspaceName}
            value={selectedWorkspaceName || ""}
            helperText=""
            selectOptions={workspaces?.map((ws) => ws.name) || []}
            onTextChange={(value) => {
              const selectedWorkspace = workspaces?.find(
                (ws) => ws.name === value
              );
              if (!selectedWorkspace) return;
              setSelectedWorkspaceName(selectedWorkspace.name);

              setReport({
                ...report,
                pbiWorkspaceId: selectedWorkspace.id,
              });
              setReportName("");
            }}
          />
        )}
      </>
      <ToggleTypeButtons type={typeOption} setType={setTypeOption} />
      {(typeOption === "report" ||
        typeOption === REPORT_TYPES.PAGINATED_REPORT) && (
        <>
          {loadingWorkspaceReports || isRefetchingWorkspaceReports ? (
            <SkeletonFormField label="Report" />
          ) : (
            <FormField
              label="Report"
              value={reportName || ""}
              helperText=""
              disabled={!selectedWorkspaceName || !profile}
              selectOptions={reportOptions?.map((r) => r.name) || []}
              onTextChange={(value) => {
                const selectedReport = workspaceReports?.find(
                  (r) => r.name === value
                );
                if (!selectedReport) return;
                const updatedReport = {
                  ...report,
                  name: selectedReport.name,
                  displayName: selectedReport.name,
                  url: selectedReport.webUrl,
                  pbiReportId: selectedReport.id,
                  pbiReportName: selectedReport.name,
                };
                setReport(updatedReport);
              }}
            />
          )}
        </>
      )}
      {typeOption === "all" && <ItemsSearch />}
      {typeOption === REPORT_TYPES.DATASET && <DatasetsFormField />}
      {typeOption === REPORT_TYPES.DATASET && <PerspectivesFormField />}
      {/* <ItemPermissionSettings
        type={type}
        permissions={permissions}
        setPermissions={setPermissions}
      /> */}
      {(isEdit || !autoTestConnection) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Button
            variant="text"
            onClick={testConnection}
            disabled={!profile || !report.pbiWorkspaceId || isTestingConnection}
            sx={{ mt: 2 }}
          >
            Test Connection
          </Button>
        </Box>
      )}
      {!isError && (
        <AccessLoadingBar
          isGrantingAccess={isGrantingAccess}
          isTestingConnection={isTestingConnection}
          isAddingProfile={isAddingProfile}
        />
      )}
      {profile &&
        selectedWorkspaceName &&
        (report?.pbiReportId || dataset?.id) &&
        ((!isGrantingAccess && !isTestingConnection) || isError) && (
          <Collapse className={classes.statusMessage} in={!!status || isError}>
            <StatusMessage
              status={
                status || {
                  type: "error",
                  message:
                    "The app registration and/or profile does not have access to the workspace",
                  title: "Unauthorized",
                }
              }
              selfFix={{
                isError: isError,
                callback: async () => {
                  setIsGrantingAccess(true);
                  setStatus(null);

                  await addProfileToWorkspace({
                    profileId: profile.id,
                    workspaceId: report.pbiWorkspaceId,
                  });
                  setIsGrantingAccess(false);
                  await testConnection();
                },
                isLoading: isAddingProfile,
              }}
            />
          </Collapse>
        )}
      <Collapse className={classes.rlsSection} in={isRlsRequired}>
        <RlsFields
          roles={report?.rls?.roles}
          username={report?.rls?.username}
          onRolesChange={(r) => {
            setReport({ ...report, rls: { roles: r, username: "email" } });
          }}
        />
      </Collapse>
    </Stack>
  );
}
