import { Box, IconButton, Stack, Typography } from "@mui/material";
import { AppRegistration, Profile } from "../../../../models";
import { NoResultsText } from "../../../../components";
import { useUpdateProfile } from "../../../../hooks";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { AddProfileForm } from "./add-connection-profile-form";
import { ToastNotificationWithTimeout } from "../../../../components/common/Toast/ToastNotificationWithTimeout";

export function LinkedProfiles(props: LinkedProfilesProps) {
  const { appRegistration, refetch } = props;

  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const profiles = appRegistration?.profiles || [];
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);

  const { mutateAsync: updateProfile } = useUpdateProfile();

  const [error, setError] = useState<string>("");

  function handleEdit(linkedProfile: Profile) {
    setSelectedProfile(linkedProfile);
    setShowEditProfileModal(true);
  }

  if (!profiles.length) {
    return (
      <div>
        <NoResultsText>
          There are no Profiles currently linked to this app registration
        </NoResultsText>
      </div>
    );
  }

  return (
    <>
      <ToastNotificationWithTimeout
        open={!!error}
        message={error}
        variant="error"
        onClose={() => {
          setError("");
        }}
        timeout={3000}
      />

      <AddProfileForm
        open={showEditProfileModal}
        onClose={async () => {
          setSelectedProfile(null);
          try {
            await updateProfile({
              profile: selectedProfile,
              appRegistrationNodeId: appRegistration.appRegistrationId,
            });
          } catch (e) {
            setError(e.message);
          }
          setShowEditProfileModal(false);
          refetch();
        }}
        onSave={() => {
          refetch();
          setShowEditProfileModal(false);
        }}
        setError={setError}
        isEdit={true}
        profileToEdit={selectedProfile}
        appReg={appRegistration}
      />
      {profiles?.map((linkedProfile) => (
        <Stack
          direction="row"
          key={linkedProfile.id}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            background: (theme) => theme.palette.grey[100],
            p: 1,
            mt: 1,
          }}
        >
          <Stack
            direction="row"
            gap={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Typography>{linkedProfile.name}</Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontSize: "0.8rem" }}
            >
              {linkedProfile.description}
            </Typography>
          </Stack>
          <Box>
            <IconButton onClick={() => handleEdit(linkedProfile)}>
              <EditIcon />
            </IconButton>
          </Box>
        </Stack>
      ))}
    </>
  );
}

type LinkedProfilesProps = {
  appRegistration: AppRegistration;
  profileId?: Profile;
  refetch: () => void;
};
