import { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import {
  ListItemText,
  Collapse,
  List,
  ListItem,
  IconButton,
  alpha,
  Theme,
} from '@mui/material'
import { FolderItem } from '../../../models'
import { NavigationLink } from './navigation-link'
import { highlightText } from './highlight-text'
import { getItemPath } from '../../../pages/reports/report-dashboard/folders/folder-utilities'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      borderTop: '1px solid #F2F2F2',
      height: '30px',
      overflow: 'hidden',
      fontSize: theme.typography.fontSize,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 1),
      },
    },
  })
)

export function FolderNav(props: FolderNavProps) {
  const { title, items, subFolder, searchText } = props
  const classes = useStyles()
  const [open, setOpen] = useState(searchText.length > 2)

  useEffect(() => {
    if (searchText.length) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <List disablePadding>
      <ListItem
        onClick={handleClick}
        className={classes.listItem}
        sx={{
          pl: subFolder ? 4 : 1,
        }}
      >
        <IconButton onClick={handleClick}>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        {/*icon*/}
        <ListItemText primary={highlightText(title, searchText)} />
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' sx={{ padding: 0 }}>
          {items?.map((item, index) => (
            <NavigationLink
              key={`navlink-${item.id}-${index}`}
              path={getItemPath(item)}
              dense
              searchText={searchText}
            >
              {item.name}
            </NavigationLink>
          ))}
        </List>
      </Collapse>
    </List>
  )
}

type FolderNavProps = {
  title: string
  items: FolderItem[]
  icon: React.ReactNode
  subFolder?: boolean
  searchText: string
}
