import { FC, PropsWithChildren, MouseEvent, useMemo } from 'react'
import {
  Box,
  Stack,
  ButtonProps,
  debounce,
  IconButton,
  Theme,
  Typography,
} from '@mui/material'
import { SxProps } from '@mui/system'

import CloseIcon from '@mui/icons-material/Close'
import {
  StyledDialog,
  StyledDialogContainer,
  StyledDialogFooter,
  StyledDialogButton,
} from './dialog.styles'
import { ContentLoader } from '../Loader/ContentLoader'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      buttonStyle: {
        height: '30px',
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        borderRadius: theme.shape.borderRadius,
        padding: '5px 10px',
        border: '1px solid',
      },
      primaryButtonStyle: {
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        '&:disabled': {
          backgroundColor: '#fff',
          borderColor: theme.palette.text.disabled,
          color: theme.palette.text.disabled,
        },
      },
      secondaryButtonStyle: {
        borderColor: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        },
        '&:disabled': {
          backgroundColor: '#fff',
          borderColor: theme.palette.text.disabled,
          color: theme.palette.text.disabled,
        },
      },
      tertiaryButtonStyle: {
        borderColor: theme.palette.error.dark,
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.error.main,
        },
        '&:disabled': {
          backgroundColor: '#fff',
          borderColor: theme.palette.text.disabled,
          color: theme.palette.text.disabled,
        },
      },
    }),
  { name: 'button' }
)

type DialogProps = {
  open: boolean
  onClose?: () => void
  title?: string | JSX.Element
  primaryButton?: boolean
  primaryButtonProps?: ButtonProps
  secondaryButton?: boolean
  secondaryButtonProps?: ButtonProps
  tertiaryButton?: boolean
  tertiaryButtonProps?: ButtonProps
  hideCloseButton?: boolean
  loading?: boolean
  paperSx?: SxProps<Theme>
  allowBackdropClickClose?: boolean
  allowEscapeKeyClose?: boolean
  error?: string
  warning?: string
  width?: number
}

const CustomDialog: FC<PropsWithChildren<DialogProps>> = (
  props: PropsWithChildren<DialogProps>
) => {
  const {
    children,
    title,
    open,
    onClose,
    primaryButton,
    primaryButtonProps,
    secondaryButton,
    secondaryButtonProps,
    tertiaryButton,
    tertiaryButtonProps,
    hideCloseButton,
    loading,
    paperSx,
    allowBackdropClickClose,
    allowEscapeKeyClose,
    error,
    warning,
    width,
  } = props

  const handlePrimaryClick = (event: MouseEvent<HTMLButtonElement>) => {
    primaryButtonProps?.onClick?.(event)
  }

  const handleSecondaryClick = (event: MouseEvent<HTMLButtonElement>) => {
    secondaryButtonProps?.onClick?.(event)
  }

  const handleDialogClose = (
    _event: unknown,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick' && !allowBackdropClickClose) return false
    if (reason === 'escapeKeyDown' && !allowEscapeKeyClose) return false
    onClose?.()
  }

  const debouncedPrimaryBtnClick = useMemo(
    () => debounce(handlePrimaryClick, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [primaryButtonProps?.onClick]
  )

  const debouncedSecondaryBtnClick = useMemo(
    () => debounce(handleSecondaryClick, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [secondaryButtonProps?.onClick]
  )

  const classes = useStyles()

  return (
    <StyledDialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby='dialog'
      PaperProps={{
        sx: {
          width: width ?? 424,
          ...paperSx,
          minHeight: '200px',
          '&::-webkit-scrollbar': {
            display: 'none !important',
          },
          //hide the scroll bar
          '-ms-overflow-style': 'none !important',
          //hide scrollbar
        },
      }}
    >
      <ContentLoader loading={loading ?? false} />
      <StyledDialogContainer
        sx={{
          ml: 0,
          p: 0,
        }}
      >
        <Box>
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{
              // pb: 2,
              // mt: 2,
              position: 'sticky',
              top: 0,
              alignItems: 'center',
              backgroundColor: theme => theme.palette.primary.main,
              color: theme => theme.palette.background.default,
              fontWeight: 600,
              height: '41px !important',
              zIndex: 100,
              ml: 0,
              p: 1,
            }}
          >
            <Box>
              {title && typeof title === 'string' && (
                <Box>
                  <Typography variant='h3'>
                    {title}
                  </Typography>
                </Box>
              )}
              {title && typeof title !== 'string' && title}
            </Box>
            {!hideCloseButton && (
              <Box>
                <IconButton color='primary' onClick={() => onClose?.()}>
                  <CloseIcon
                    sx={{ fill: theme => theme.palette.background.default }}
                  />
                </IconButton>
              </Box>
            )}
          </Stack>
          <Box
            sx={{
              position: 'sticky',
              top: '41px',
              height: '2px',
              background: `linear-gradient(to bottom, 
        rgba(255, 255, 255, 0.8) 100%, rgba(255, 255, 255, 0.2) 0%)`,
              //blur the content behind the dialog
              // backdropFilter: 'blur(4px)',
              width: '100%',
              pointerEvents: 'none',
              zIndex: 100,
            }}
          />
          <Box sx={{ pt: 1, pl: 2, pr: 2 }}>
            <Stack
              direction='column'
              justifyContent='space-between'
              sx={{ pb: 2, height: '100%' }}
            >
              {children}
              {error && (
                <Box>
                  <Typography
                    sx={{
                      width: '100%',
                      color: 'red',
                      marginTop: '4px',
                      paddingLeft: '4px',
                    }}
                    fontSize={14}
                  >
                    {error}
                  </Typography>
                </Box>
              )}
              {warning && (
                <Box>
                  <Typography
                    sx={{
                      width: '100%',
                      color: 'orange',
                      marginTop: '4px',
                      paddingLeft: '4px',
                    }}
                    fontSize={14}
                  >
                    {warning}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'sticky',
            bottom: '64px',
            height: '2px',
            background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.8) 100%)`,
            // backdropFilter: 'blur(4px)',
            width: '100%',
            pointerEvents: 'none',
            zIndex: 100,
          }}
        />
        {(primaryButtonProps ||
          secondaryButtonProps ||
          tertiaryButtonProps) && (
          <StyledDialogFooter
            justifyContent='center'
            sx={{  p: 2 }}
          >
            {tertiaryButtonProps || tertiaryButton ? (
              <StyledDialogButton
                className={`${classes.tertiaryButtonStyle} ${classes.buttonStyle}`}
                variant='text'
                color='primary'
                {...tertiaryButtonProps}
                onClick={tertiaryButtonProps?.onClick}
              >
                {tertiaryButtonProps?.children ?? 'Delete'}
              </StyledDialogButton>
            ) : (
              <Box />
            )}
            <Box display='flex' gap={1}>
              {(secondaryButtonProps || secondaryButton) && (
                <StyledDialogButton
                  className={`${classes.secondaryButtonStyle} ${classes.buttonStyle}`}
                  variant='text'
                  color='primary'
                  {...secondaryButtonProps}
                  onClick={debouncedSecondaryBtnClick}
                  sx={{
                    ml: 1,
                    pl: 2,
                    pr: 2,
                    border: '1px solid',
                    borderRadius: '5px',
                    borderColor: theme => theme.palette.primary.main,
                    '&:hover': {
                      color: '#ffffff',
                      backgroundColor: theme => theme.palette.primary.main,
                    },
                  }}
                >
                  {secondaryButtonProps?.children ?? 'No'}
                </StyledDialogButton>
              )}
              {(primaryButtonProps || primaryButton) && (
                <StyledDialogButton
                  className={`${classes.primaryButtonStyle} ${classes.buttonStyle}`}
                  variant='text'
                  color='primary'
                  {...primaryButtonProps}
                  onClick={e => {
                    if (!error) {
                      debouncedPrimaryBtnClick(e)
                    }
                  }}
                >
                  {primaryButtonProps?.children ?? 'Yes'}
                </StyledDialogButton>
              )}
            </Box>
          </StyledDialogFooter>
        )}
      </StyledDialogContainer>
    </StyledDialog>
  )
}

export default CustomDialog
