import React, { useEffect, useState } from 'react'
import {
  Box,
  Icon,
  IconButton,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { InfoOutlined, Menu as MenuIcon } from '@mui/icons-material'
import { makeStyles, createStyles } from '@mui/styles'
import cs from 'classnames'
import { useSetRecoilState } from 'recoil'
import { MainAppBar } from './main-app-bar'
import { ResponsiveDrawer } from './responsive-drawer'
import { NavigationLinks } from './navigation-drawer/navigation-links'
import { FullPageLoader } from '../common'
import { ErrorPage } from '../../pages/reports/report-view/error-page'
import { bookmarkAtom } from '../../state'

const pageWidth = 1800
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      overflow: 'auto',
    },
    appBarOffset: {
      height: '50px',
    },
    main: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      flexGrow: 1,
      // padding: theme.spacing(3),
      overflow: 'hidden',
    },
    fullWidth: {
      width: '100% !important',
      maxWidth: 'unset !important',
      padding: '0 !important',
    },
    mainOpen: {
      // [theme.breakpoints.up('lg')]: {
      //   transition: theme.transitions.create('margin', {
      //     easing: theme.transitions.easing.easeOut,
      //     duration: theme.transitions.duration.enteringScreen,
      //   }),
      //   marginLeft: '273px',
      // },
    },
    hideOnMobile: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    content: {
      maxWidth: `${pageWidth}px`,
      height: 'calc(100% - 50px)',
      margin: '0 auto',
    },
  })
)

export function Layout(props: LayoutProps) {
  const {
    children,
    title,
    infoButtonTooltip,
    infoButtonOnClick,
    showBackButton,
    fullWidth,
    hideLogo,
    hideBackground,
    bookmarkOnClick,
    loading,
    error,
    errorMessage,
    loadingMessage,
    reportOptions,
    favoritesButton,
    sx,
    ...rest
  } = props

  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(o => !o)
  // const bookmarkState = useRecoilValue(bookmarkAtom)
  const setBookmarkState = useSetRecoilState(bookmarkAtom)

  useEffect(() => {
    if (!isOpen)
      return setBookmarkState(state => ({ ...state, panelOpen: false }))
  }, [isOpen, setBookmarkState])

  return (
    <Stack {...rest} sx={{ overflow: 'auto', ...sx }}>
      <MainAppBar
        toggleOpen={toggleOpen}
        showBackButton={showBackButton}
        title={title}
        fullWidth={fullWidth}
      />
      <ResponsiveDrawer toggleOpen={toggleOpen} isOpen={isOpen}>
        <NavigationLinks />
      </ResponsiveDrawer>
      <main
        className={cs(classes.main, {
          [classes.mainOpen]: isOpen,
        })}
      >
        {!hideBackground && <Background />}
        <Box className={classes.appBarOffset} />
        <Stack
          direction='row'
          alignItems='center'
          sx={{ height: '50px' }}
          justifyContent={'space-between'}
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent={'flex-start'}
            gap={0}
          >
            <IconButton
              sx={{ alignSelf: 'flex-start' }}
              color='inherit'
              aria-label='menu'
              onClick={toggleOpen}
              size='large'
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h1' sx={{ textTransform: 'uppercase' }}>
              {title ?? 'Analytics Hub'}
            </Typography>
            {infoButtonTooltip && (
              <Tooltip title='Report Info'>
                <Icon
                  color='inherit'
                  aria-label='reset'
                  sx={{
                    color: theme => theme.palette.primary.main,
                    '&:hover': {
                      color: theme => theme.palette.primary.dark,
                      backgroundColor: 'transparent',
                    },
                    cursor: 'pointer',
                  }}
                  onClick={() => infoButtonOnClick()}
                >
                  <InfoOutlined sx={{ fontSize: '16px', mb: 2 }} />
                </Icon>
              </Tooltip>
            )}
            {favoritesButton}
          </Stack>
          {reportOptions}
        </Stack>
        <Box
          className={cs(classes.content, {
            [classes.fullWidth]: fullWidth,
          })}
        >
          {loading ? (
            <FullPageLoader loadingMessage={loadingMessage} />
          ) : error ? (
            <ErrorPage>
              {errorMessage ||
                'Error: User does not have permissions to view this page'}
            </ErrorPage>
          ) : (
            children
          )}
        </Box>
      </main>
    </Stack>
  )
}

function Background() {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: -1,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'theme.palette.background.',
      }}
    />
  )
}

type LayoutProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  title?: string
  infoButtonTooltip?: string
  infoButtonOnClick?: () => void
  showBackButton?: boolean
  fullWidth?: boolean
  hideLogo?: boolean
  hideBackground?: boolean
  bookmarkOnClick?: () => void
  loading?: boolean
  error?: boolean
  errorMessage?: string
  loadingMessage?: string
  sx?: any
  reportOptions?: React.ReactNode
  favoritesButton?: React.ReactNode
}
